.login-dark {
    height: 100vh; /* This ensures it takes the full viewport height */
    background: linear-gradient(315deg, #2a2a72, #009ffd);
    position: relative;
}
  
.login-dark form {
  max-width: 500px; /* Increased from 320px */
  width: 90%;
  background-color: #1e2833;
  padding: 40px 50px; /* Increased padding on left and right for a wider appearance */
  border-radius: 4px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
}
  
  .login-dark .illustration {
    text-align:center;
    padding:15px 0 20px;
    font-size:100px;
    color:#2980ef;
  }
  
  .login-dark form .form-control {
    background: none;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: #ccc; /* Lightened color */
}

  
  .login-dark form .btn-primary {
    background:#214a80;
    border:none;
    border-radius:4px;
    padding:11px;
    box-shadow:none;
    margin-top:26px;
    text-shadow:none;
    outline:none;
  }
  
  .login-dark form .btn-primary:hover, .login-dark form .btn-primary:active {
    background:#214a80;
    outline:none;
  }
  
  .login-dark form .forgot {
    display:block;
    text-align:center;
    font-size:12px;
    color:#6f7a85;
    opacity:0.9;
    text-decoration:none;
  }
  
  .login-dark form .forgot:hover, .login-dark form .forgot:active {
    opacity:1;
    text-decoration:none;
  }
  
  .login-dark form .btn-primary:active {
    transform:translateY(1px);
  }

  .login-dark form .form-control::placeholder {
    color: #999; /* Lighter color for placeholder text */
}
  
.form-spacing {
  height: 20px; /* or any value you prefer */
}

.message-error {
  font-size: 1.5em; /* Adjust the value as per your liking */
  color: red; /* Optional: Set a color for error messages. */
}


  
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */
.navbar {
  background: rgba(0, 0, 0, 0.8);
  height: 60px; /* Adjust as needed */
  display: flex;
  align-items: center; /* This will vertically center-align items */
  padding: 0 20px; /* Adds some padding on the left and right */
}

.navbar a {
  font-size: 24px; /* Adjust as needed */
  margin-right: 20px; /* Adds some space between the links */
  text-decoration: none;
  color: #fff; /* White text color */
}

.navbar a:last-child {
  margin-right: 0; /* Removes margin from the last link */
}

.navbar .icon {
  font-size: 32px; /* Adjust as needed to make the icon bigger */
}
